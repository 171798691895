import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  return (
    <Layout>
      <Helmet>
        <title>Hyloq | Terms Of Service</title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://hyloq.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Hyloq is the first true-scope geofencing advertising platform, Geofenced ads matched with unparalled data feedback for effectkive, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Hyloq is the first true-scope geofencing advertising platform. Geofenced ads matched with unparalled data feedback for effective, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://hyloq.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>
      <div className="relative bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-20 relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Terms of Service
              </h3>
            </div>
            <div className="pt-6">
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Terms of Use
              </h1>
              <p class="text-gray-500">
                Hyloq.com and all of its subdomains and aliases (the “Website”
                or “Websites”), are sites owned and operated by Hyloq, LLC
                (referred to herein as “Hyloq”, “we”, “us”, “our” and “ours”).
                Unless otherwise indicated, the terms “you”, “your” and “yours”
                when used in these Terms of Use refers to any user or visitor of
                this website. The following terms and conditions (the “Terms of
                Service or Terms of Use”) form a binding agreement between Hyloq
                and visitors to our websites.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Agreement
              </h1>
              <p class="text-gray-500">
                Please read these Terms of Use carefully. Your access to and use
                of this website, and any of the information, materials, data,
                demos, blogs, products, services and other materials made
                available on or through it (collectively, “Materials”), are
                subject to all applicable laws, rules and regulations and to all
                of the terms and conditions set forth in these Terms of Use.
              </p>
              <br />
              <p class="text-gray-500">
                By accessing or using this website, you agree to be bound by
                these Terms of Use. If you are accessing or using this website
                on behalf of an entity or business, you represent and warrant
                that you have the authority to bind such entity or business to
                these Terms of Use. If you do not wish to be bound by these
                Terms of Use, please exit this website and cease use of all
                Materials, as you are not permitted to access or use this
                website or such Materials.
              </p>
              <br />
              <p class="text-gray-500">
                These Terms of Use may be changed by us from time to time, at
                our sole discretion, without notice to you. Any changes or
                modifications to these Terms of Use will be effective upon
                posting on the website. You should always review these Terms of
                Use prior to your access or use of the website or Materials to
                ensure that you understand the current Terms of Use that apply
                to your access or use thereof. Your continued use of the website
                following the posting of any changes or modifications will
                constitute your acceptance of such changes or modifications. If
                you cannot access these Terms of Use via the Internet, we can
                provide a copy of the Terms of Use then in effect by e-mail upon
                request.
              </p>
              <br />
              <p class="text-gray-500">
                YOUR ACCESS TO THIS WEBSITE (OR PORTIONS THEREOF) AND USE OF ANY
                MATERIALS (OR PORTIONS THEREOF) IS NOT INTENDED, AND IS
                PROHIBITED, WHERE SUCH ACCESS OR USE VIOLATES ANY APPLICABLE
                LAWS, RULES OR REGULATIONS.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Access and Use Restrictions
              </h1>
              <p class="text-gray-500">
                Access and use of the Hyloq products, services, documentation
                and related materials are solely authorized for the internal
                business purposes of the organization in which you are a
                representative of and only for the duration of the term of your
                subscription period. Use of the Hyloq products, services,
                documentation, and related materials, for analytical or research
                purposes to be used or disclosed outside of your organization is
                strictly prohibited. Sharing screenshots, downloads, or other
                forms of copying, duplicating or replicating the products,
                services, documentation and other related materials publicly, or
                otherwise outside of your organization, is strictly prohibited.
                You are not permitted to access or use any of Hyloq’s products,
                services, documentation or other related materials if you are,
                or the organization in which you represent is, a competitor of
                Hyloq. Additionally, you are not authorized to provide, or
                otherwise send to or communicate, to a competitor of Hyloq any
                information gained by your access and use.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Product & Services Information
              </h1>
              <p class="text-gray-500">
                All references on this website to information, materials,
                products and/or services apply to information, materials,
                products and services available in the countries or
                jurisdictions specified, unless otherwise stated. Nothing in
                this website constitutes an offer to buy or sell our products or
                services in any jurisdiction.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Ownership and Permitted Use of Materials
              </h1>
              <p class="text-gray-500">
                Unless otherwise indicated and except for materials in the
                public domain, this website and its design, text, content,
                files, selection and arrangement of elements, organization,
                graphics, compilation, translations, digital conversion and
                other matters related to, and all trademarks, logos, service
                marks, symbols, trade dress and all Materials provided on or
                through this website are protected by all applicable copyright
                laws, trademark laws and/or international conventions and
                treaties. All Materials are the exclusive and proprietary
                property of Hyloq and/or its third-party licensors. ALL RIGHTS
                RESERVED.
              </p>
              <br />
              <p class="text-gray-500">
                The posting display or other provision of any Materials on this
                website shall not constitute a waiver of any right or interest
                of Hyloq and/or its third-party licensors in such Materials.
                Except as expressly provided herein, nothing contained in these
                Terms of Use or the website shall be construed as conferring by
                implication, or otherwise, any license, interest or right in or
                to the Materials under any copyright, trademark or proprietary
                rights of Hyloq or of any third party. Further, except as
                otherwise provided herein, no Materials (or portions thereof)
                may be used, copied, reproduced, distributed or redistributed,
                published or republished, downloaded, modified, displayed,
                posted, broadcasted, imitated, adapted, translated, or
                transmitted in any form or by any means, including, but not
                limited to, electronic, mechanical, photocopying, recording, or
                otherwise, or sold, offered for sale, licensed or sublicensed,
                reverse engineered, disassembled, decompiled or incorporated
                into compilations or used in the creation of derivative works,
                without our express prior written permission or that of the
                respective third-party owner.
              </p>
              <br />
              <p class="text-gray-500">
                Subject to your compliance with the foregoing, permission is
                hereby granted to you to lawfully: (a) access this website
                and/or Materials made available on or through this website; and
                (b) display, download, archive and print in hard copy portions
                of this website for your personal, non-commercial and
                informational use only.
              </p>
              <br />
              <p class="text-gray-500">
                Such permission is conditional providing that you do not: (a)
                modify the Materials (or any portion thereof), (b) use the
                website and/or the Materials in a manner that is fraudulent,
                libelous, defamatory, tortious, obscene, threatening, abusive,
                malicious, offensive, hateful, objectionable, constitutes or
                encourages a criminal offense, violates the rights of another
                (including, without limitation, any copyright, trademark,
                patent, trade secret, privacy, moral or publicity rights or
                other intellectual property or proprietary rights of others), or
                otherwise violates or conflicts with these Terms of Use or any
                applicable law; (c) impersonate any person or entity, or
                otherwise misrepresent your affiliation with any person or
                entity; (d) use the website to interfere, disrupt or attempt to
                gain unauthorized access to the Materials, other Hyloq accounts,
                any restricted portions of the website or any computer network
                or equipment; (e) use any manual or automated software, devices
                or other processes to “crawl” or “spider” any web pages
                contained in the website (including, without limitation, the use
                of robots, bots, spiders, scrapers or any other means to extract
                any Materials or other data from the website); (f) frame or
                otherwise simulate the appearance or functions of the website
                (or any portion thereof); and/or (d) remove or alter any
                copyright, trademark and other proprietary notices and legends
                contained in the Materials or on the website (and any printouts
                thereof). The foregoing permission is for the sole purpose of
                enabling you to use the website and enjoy the benefit of the
                Materials as provided on the website, in the manner permitted by
                these Terms of Use.
              </p>
              <br />
              <p class="text-gray-500">
                Website materials may be changed by us from time to time, at our
                sole discretion, without notice to you. You are solely
                responsible for ensuring that you are using current and
                permitted Materials only. The foregoing permission terminates
                automatically if you breach any of these Terms of Use. Upon
                termination, you must immediately destroy any and all
                downloaded, archived or printed copies of the Materials, and, if
                requested by us, certify to us in writing that you have complied
                with the foregoing.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Violations
              </h1>
              <p class="text-gray-500">
                It is the policy of Hyloq to provide for the termination, in
                appropriate circumstances, of Hyloq customers and account
                holders who repeatedly violate these Terms of Use.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Disclaimer of Endorsement
              </h1>
              <p class="text-gray-500">
                Any references to business or entities, products, processes, or
                services, or other organizations or individuals that are
                included on the website by trade name, trademark or otherwise
                are provided solely for your information. These references are
                not intended to reflect the opinions of Hyloq concerning the
                significance, priority or importance to be given the referenced
                business, entity, product, service, organization or individual.
                Such references, including any that may relate to our Materials,
                are not endorsements or approvals by Hyloq, and should not be
                quoted or reproduced for the purpose of stating or implying
                endorsement or approval by Hyloq.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Minors
              </h1>
              <p class="text-gray-500">
                This website is a business and commercial site. As such, it is
                not intended for children or minors. You affirm that you are
                either more than 18 years of age or possess legal parental or
                guardian consent and are fully able and competent to enter into
                and to abide by and comply with these Terms of Use.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                User-Generated Content
              </h1>
              <p class="text-gray-500">
                By agreeing to these Terms of Use, you agree to the terms of our
                Privacy Policy as posted elsewhere on the website. You may from
                time to time be permitted to submit e-mails or otherwise provide
                content to us in connection with your use of the website or
                Materials (“User-Generated Content”). Any communication or
                material you post on the website or transmit to us over the
                Internet is, and will be, treated as non-confidential and
                non-proprietary and is and will be solely your responsibility.
                Upon the transmission of any User-Generated Content to us, you
                expressly and hereby grant us perpetual, worldwide,
                royalty-free, irrevocable, transferable, sublicensable and
                non-exclusive permission to reproduce, display, publish, modify,
                distribute, disseminate and/or otherwise use or exploit such
                content for any lawful purpose, without compensation, notice or
                obligation to you, subject to our Privacy Policy. We are not
                required to use or return any User Generated Content submitted
                to us. You represent and warrant that you own or otherwise
                control all rights, including, but not limited to, copyrights.
                Further, as to any such User-Generated Content, by your use you
                affirm that all moral rights in and to any such User-Generated
                Content have been waived, and that the use of such
                User-Generated Content as permitted hereunder does not and will
                not infringe or violate the rights of any third party. All
                derivative works or other developments arising from or based on
                User-Generated Content, if created by us, shall be our exclusive
                property.
              </p>
              <br />
              <p class="text-gray-500">
                The posting or transmittal of any unlawful, threatening,
                libelous, defamatory, obscene, pornographic or profane material
                or any material that could constitute or encourage conduct that
                would be considered a criminal offense or violation of any law
                is strictly prohibited. Under no circumstances shall we be
                liable for any such material posted or transmitted by you by or
                through the website.
              </p>
              <br />
              <p class="text-gray-500">
                We reserve the right to monitor your communications with us
                whether by mail, voice, fax, email or any other form of
                transmission for the purposes of quality control, security and
                other business needs.
              </p>
              <br />
              <p class="text-gray-500">
                Notwithstanding the foregoing, all personal data provided to us
                in connection with your use of this website will be handled in
                accordance with our Privacy Policy.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Statements on Website
              </h1>
              <p class="text-gray-500">
                The documents contained on (or directly accessible from) this
                website may contain a number of forward-looking statements. Any
                statements that are not statements of historical fact
                (including, without limitation, statements to the effect that
                Hyloq or its management “believes,” “expects,” “anticipates,”
                “plans” and other similar expressions) should be considered
                forward-looking statements and should not be relied upon.
              </p>
              <br />
              <p class="text-gray-500">
                There are a number of important factors that could cause Hyloq’s
                actual results to differ materially from those indicated by such
                forward-looking statements, including general economic
                conditions, our continued ability to develop and introduce
                products, the introduction of new products by competitors,
                pricing practices of competitors, expansion of its sales
                distribution capability, the cost and availability of
                components, undetected software errors or bugs, our ability to
                control costs and other risk factors.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                No Warranties
              </h1>
              <p class="text-gray-500">
                WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS
                WEBSITE OR THE MATERIALS, WHICH ARE PROVIDED “AS IS.” WE
                DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                LIMITATION, THE IMPLIED WARRANTIES OF TITLE, NONINFRINGEMENT,
                MERCHANTABILITY, QUALITY AND FITNESS FOR A PARTICULAR PURPOSE,
                WITH RESPECT TO THIS WEBSITE, THE MATERIALS AND ANY WEBSITE WITH
                WHICH THIS WEBSITE IS LINKED. YOU ARE SOLELY RESPONSIBLE FOR
                PROVIDING, MAINTAINING AND ENSURING THE COMPATIBILITY OF ALL
                HARDWARE, SOFTWARE AND OTHER COMPUTER REQUIREMENTS NECESSARY FOR
                YOUR ACCESS TO AND USE OF THE WEBSITE AND THE MATERIALS. WE ALSO
                MAKE NO REPRESENTATIONS OR WARRANTIES AS TO WHETHER (A) THE
                INFORMATION ACCESSIBLE VIA THIS WEBSITE, OR ANY WEBSITE WITH
                WHICH IT IS LINKED, IS ACCURATE, RELIABLE, COMPLETE, OR CURRENT,
                (B) USE OF THE WEBSITE AND ACCESS TO THE MATERIALS WILL BE
                UNINTERRUPTED OR ERROR-FREE, (C) THE WEBSITE AND THE MATERIALS
                WILL BE AVAILABLE AT ANY PARTICULAR TIME, OR (D) THE WEBSITE AND
                MATERIALS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                NEITHER WE NOR ANY THIRD-PARTY LICENSOR WILL BE LIABLE WITH
                RESPECT TO ANY DECISIONS MADE BY YOU OR ANY OTHER PERSON AS A
                RESULT OF RELIANCE ON THE WEBSITE OR ANY MATERIALS. BECAUSE SOME
                JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN WARRANTIES,
                THESE EXCLUSIONS MAY NOT APPLY TO YOU. YOU ACCEPT THAT OUR
                SHAREHOLDERS, OWNERS, OFFICERS, DIRECTORS, EMPLOYEES AND OTHER
                REPRESENTATIVES SHALL HAVE THE BENEFIT OF THIS CLAUSE.
              </p>
              <br />
              <p class="text-gray-500">
                Your use of the website and the Materials is at your sole risk.
                It is your responsibility to evaluate (or take professional
                advice on) the accuracy and completeness of all information,
                statements, opinions and other material on this website or any
                web site with which it is linked.
              </p>
              <br />
              <p class="text-gray-500">
                Your statutory rights as a consumer, if any, are not affected by
                these provisions, and we do not seek to exclude or limit
                liability for fraudulent misrepresentation.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Exclusion of Liability
              </h1>
              <p class="text-gray-500">
                To the fullest extent permitted by applicable law, neither we
                nor any of our shareholders, officers, directors,
                representatives, employees, agents, third-party licensors,
                suppliers, and contractors shall be liable for any losses and/or
                expenses of whatever nature, howsoever arising and regardless of
                the legal theory of liability, including, without limitation,
                any direct, indirect, special, punitive, incidental or
                consequential damages, loss of or inability to use, loss of
                data, loss caused by a virus, loss of income or profit, loss of
                or damage to property, claims of third parties, or other losses
                of any kind or character, even if we have been advised of the
                possibility of such damages, expenses or losses and even if an
                exclusive remedy provided for herein fails of its essential
                purpose, arising out of or in connection with your access or use
                of, or inability to use, this website or the Materials or any
                web site with which this website is linked. If you are
                dissatisfied with the website or any Materials, your sole and
                exclusive remedy shall be to discontinue use of the website and
                Materials and terminate these Terms of Use in accordance with
                its terms. To the maximum extent permitted by law, in no event
                shall Hyloq’s or any third-party licensor’s total liability to
                you for damages, losses and causes of action (whether in
                contract, tort (including, but not limited, to negligence) or
                otherwise) exceed in the aggregate the amount paid by you, if
                any, for accessing the website or Materials.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Indemnity and Release
              </h1>
              <p class="text-gray-500">
                You agree to defend, indemnify, release and hold us harmless as
                well as our shareholders, officers, directors, agents,
                representatives, employees and third-party licensors from any
                and all claims, liabilities, losses, expenses or demands,
                including reasonable legal fees, based on, arising from, or
                otherwise related to (a) your breach or violation of these Terms
                of Use; (b) your access or use of this website or any of the
                information, materials, products or services made available on
                or through this website; (c) any infringement or
                misappropriation by you of any intellectual property or other
                rights of Hyloq or any third party; or (d) any negligence or
                willful misconduct by you.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Modification and Discontinuation
              </h1>
              <p class="text-gray-500">
                We reserve the right at any time to modify, edit, delete,
                suspend or discontinue, temporarily or permanently, this website
                (or any portion thereof) and/or the information, materials,
                products and/or services made available on or through this
                website (or any part thereof) with or without notice. You agree
                that we shall not be liable to you or to any third party for any
                such modification, editing, deletion, suspension, or
                discontinuance of this website.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Termination and Survival
              </h1>
              <p class="text-gray-500">
                These Terms of Use will remain in effect until terminated by you
                or us. You may terminate these Terms of Use at any time by
                ceasing your use and access of the website and Materials and by
                destroying all materials that you have saved, printed or
                downloaded from the website.
              </p>
              <br />
              <p class="text-gray-500">
                Notwithstanding any of the terms and conditions contained
                herein, we reserve the right, without notice and at our sole
                discretion, to terminate your permission to use the website, and
                to block or prevent future access to and use of the website and
                Materials. The provisions of these Terms of Use that by their
                nature and content are intended to survive the expiration or
                termination of these Terms of Use will survive such termination
                or expiration.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Termination and Survival
              </h1>
              <p class="text-gray-500">
                These Terms of Use contain the entire understanding between you
                and Hyloq with respect to this website and your use and access
                of this website and of any of the information, materials, data,
                demos, blogs, products, services and other materials made
                available on or through this website. Any other representations,
                statements, inducements, understandings, arrangements and
                agreements, oral or written, between you and Hyloq, either prior
                or contemporaneous, relating thereto, not contained herein, are
                expressly made null and void, unless signed in writing by Hyloq.
                For the avoidance of doubt, you agree that any terms and
                conditions provided in any Service Order or other document
                delivered to Hyloq by you in connection with the Services shall
                in no way supersede the terms and conditions of this Agreement
                and are hereby declared null and void, unless signed in writing
                by Hyloq.{" "}
              </p>
              <br />
              <p class="text-gray-500">
                Should there be a conflict between these Terms of Use and any
                written and signed agreement between Hyloq and any customer, the
                terms of such written and signed agreement shall control. Should
                any part of these terms for any reason be declared invalid, void
                or unenforceable by a court of competent jurisdiction, such
                determination shall not affect the validity of any remaining
                portions and such remaining portions shall remain and continue
                in full force and effect to the maximum extent permitted by law.
                Our failure to enforce any provision of these Terms of Use shall
                not operate as or be deemed a waiver by Hyloq of any other
                breach of any such provision or any other provision herein or of
                the right to enforce such provision(s).
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Applicable Law & Jurisdiction
              </h1>
              <p class="text-gray-500">
                While Hyloq provides global products and services, our
                operations are in located in Clearwater, Florida with data
                centers geographically spread worldwide, and these Terms of Use
                are based on United States law. Access to, or use of, this
                website or any information, materials, products and/or services
                made available on or through this website may be prohibited by
                law in certain countries or jurisdictions. You are solely
                responsible for ensuring compliance with any applicable laws of
                the country from which you are accessing this website. We make
                no representation that the information contained herein is
                appropriate or available for use in any location.
              </p>
              <br />
              <p class="text-gray-500">
                You agree that these Terms of Use are made and partially
                performed in the State of Florida, and that the state courts
                located within Pinellas County, Florida and the federal district
                court in the Middle District of Florida located in Tampa,
                Florida, without giving effect to any principles of conflicts of
                law, shall have exclusive jurisdiction to resolve any
                controversy or claim of whatever nature arising out of or
                relating to your access and use of this Website, and that the
                laws of the State of Florida shall govern such dispute or claim.
                You hereby consent and submit to the exercise of personal
                jurisdiction over you by such courts for the purposes of
                litigating any such action and waive any defenses of lack of
                minimum contacts or forum non-conveniens. Notwithstanding the
                foregoing, we reserve the right to bring legal proceedings in
                any jurisdiction where we believe that infringement of these
                Terms of Use may be taking place or originating.
              </p>
              <br />
              <p class="text-gray-500">
                The rights and remedies provided by these Terms of Use are
                cumulative and the use of any right or remedy by us shall not
                preclude or waive our rights to use any or all other remedies.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Contacting Us
              </h1>
              <p class="text-gray-500">
                If you have any questions regarding these Terms of Use or any
                other matter, you may contact us in by writing to us at Hyloq
                Inc., 1659 Achieva Way, Dunedin, FL 34698 or email us at
                “webmaster at Hyloq.com”.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfService;
